import React, { useState, useEffect } from 'react';

import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTheme } from '../ThemeContext';

const Header = () => {
  const location = useLocation();
  const { theme, toggleTheme } = useTheme();
  const [stickyClass, setStickyClass] = useState('relative');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const homeNav = () => {
    if (document.body.classList.contains('home-sm') || document.body.classList.contains('mega-toggle')) {
      document.body.classList.remove('home-sm');
      document.body.classList.remove('mega-toggle');
    } else {
      document.body.classList.add('home-sm');
    }
  };
  const homeNavClose = () => {
    if (document.body.classList.contains('home-sm') || document.body.classList.contains('mega-toggle')) {
      document.body.classList.remove('home-sm');
      document.body.classList.remove('mega-toggle');
    }
  }

  const megaMenu = () => {
    if (document.body.classList.contains('mega-toggle')) {
      document.body.classList.remove('mega-toggle');
    } else {
      document.body.classList.add('mega-toggle');
    }
  };

  const backBtn = () => {
    if (document.body.classList.contains('mega-toggle')) {
      document.body.classList.remove('mega-toggle');
    } 
  };


  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setStickyClass('bg-pink') : setStickyClass('');
    }
  };
  // Check if the current path is '/nodesale'
  let isNodePage = location.pathname === '/nodesale';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  return (
    <div>
      
      <nav
        className={`navbar navbar-expand-lg flex-column transition-all pt-0 pb-lg-0 ${stickyClass}`}>
                  {!isNodePage && (
          <div className="top-banner"><div className="container">
          The StratoVM Node Sale is live -  <a href="https://nodes.stratovm.io/" target="_blank">Buy Now</a> </div></div>)}
        <div className="container position-relative pt-1">
        {!isNodePage ? (
          <Link to="/" className="navbar-brand">
            <img src="assets/logo.svg" className="light-logo op-dark" alt="" />
            <img src="assets/logo-vm.svg" className="light-logo op-light" alt="" />
          </Link>) : (
          <Link to="/" className="navbar-brand">
            <img src="assets/nodesalev2/logo.svg" className="light-logo op-dark" alt="" />
            <img src="assets/nodesalev2/logo-vm.svg" className="light-logo op-light" alt="" />
          </Link>)}
          <div className="d-lg-none d-inline-flex gap-2 align-items-center">
          <a href="javascript:void(0)" className="toogle-btn" onClick={toggleTheme}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_509_17)">
                  <path d="M15.9986 6.21228C10.5931 6.21228 6.21094 10.5944 6.21094 16C6.21094 21.4056 10.5931 25.7877 15.9986 25.7877C21.4042 25.7877 25.7863 21.4056 25.7863 16C25.7863 10.5944 21.4042 6.21228 15.9986 6.21228ZM15.9986 24V8C20.4169 8 23.9986 11.5818 23.9986 16C23.9986 20.4183 20.4168 24 15.9986 24Z" fill="white"/>
                  <path d="M23.865 8.13412C24.03 8.30256 24.2549 8.39888 24.4907 8.40231C24.7265 8.39897 24.9514 8.30256 25.1164 8.13412L27.3063 5.94418C27.7013 5.64793 27.7813 5.0877 27.4851 4.69274C27.189 4.29778 26.6286 4.2178 26.2337 4.51395C26.1659 4.56477 26.1057 4.62499 26.0549 4.69274L23.8649 6.88269C23.5193 7.19972 23.4962 7.73694 23.8134 8.08245C23.8299 8.10042 23.8471 8.11761 23.865 8.13412Z" fill="white"/>
                  <path d="M7.95566 23.866C7.63786 23.6276 7.20081 23.6276 6.88301 23.866L4.69307 26.0559C4.34747 26.3729 4.32437 26.9102 4.64148 27.2557C4.65791 27.2736 4.67519 27.2908 4.69307 27.3073C5.0101 27.6529 5.54732 27.676 5.89283 27.3589C5.9108 27.3424 5.92799 27.3251 5.94442 27.3073L8.13436 25.1173C8.43061 24.7224 8.35062 24.1621 7.95566 23.866Z" fill="white"/>
                  <path d="M27.3076 26.0559L25.1177 23.866C24.7227 23.5697 24.1625 23.6498 23.8662 24.0448C23.6279 24.3626 23.6279 24.7996 23.8662 25.1174L26.0562 27.3074C26.3732 27.653 26.9104 27.6761 27.256 27.3589C27.2739 27.3425 27.2911 27.3252 27.3075 27.3074C27.6531 26.9903 27.6762 26.4531 27.3591 26.1076C27.3428 26.0895 27.3256 26.0724 27.3076 26.0559Z" fill="white"/>
                  <path d="M6.8844 8.13412C7.20143 8.47972 7.73865 8.50282 8.08417 8.1857C8.10213 8.16928 8.11933 8.152 8.13575 8.13412C8.48135 7.81709 8.50445 7.27987 8.18734 6.93435C8.17091 6.91639 8.15363 6.89919 8.13575 6.88277L5.94581 4.69282C5.55085 4.39658 4.99061 4.47665 4.69437 4.87161C4.45604 5.18941 4.45604 5.62646 4.69437 5.94426L6.8844 8.13412Z" fill="white"/>
                  <path d="M15.9993 4.87152C16.493 4.87152 16.8932 4.47134 16.8932 3.97766V0.893858C16.8932 0.400179 16.493 0 15.9993 0C15.5056 0 15.1055 0.400179 15.1055 0.893858V3.97766C15.1055 4.47134 15.5056 4.87152 15.9993 4.87152Z" fill="white"/>
                  <path d="M15.9993 27.1286C15.5056 27.1286 15.1055 27.5287 15.1055 28.0224V31.1062C15.1055 31.5999 15.5056 32.0001 15.9993 32.0001C16.493 32.0001 16.8932 31.5999 16.8932 31.1062V28.0224C16.8932 27.5287 16.493 27.1286 15.9993 27.1286Z" fill="white"/>
                  <path d="M31.1066 15.1062H28.0228C27.5291 15.1062 27.1289 15.5064 27.1289 16.0001C27.1289 16.4937 27.5291 16.8939 28.0228 16.8939H31.1066C31.6002 16.8939 32.0004 16.4937 32.0004 16.0001C32.0004 15.5064 31.6002 15.1062 31.1066 15.1062Z" fill="white"/>
                  <path d="M4.87152 16.0001C4.87152 15.5064 4.47134 15.1062 3.97766 15.1062H0.893858C0.400179 15.1062 0 15.5064 0 16.0001C0 16.4937 0.400179 16.8939 0.893858 16.8939H3.97766C4.47134 16.8939 4.87152 16.4937 4.87152 16.0001Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_509_17">
                  <rect width="32" height="32" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>
                  </a>
                  {!isNodePage && (
                  <div className="dropdown">
                      <button className="language-dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" 
                      data-bs-toggle="dropdown" aria-expanded="false">
                        EN
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                        <li className='active'><a className="" href="#">English</a></li>
                        <li><a className="" href="#">Arabic <span>Soon</span></a></li>
                        <li><a className="" href="#">Chinese <span>Soon</span></a></li>
                        <li><a className="" href="#">Spanish <span>Soon</span></a></li>
                        <li><a className="" href="#">French <span>Soon</span></a></li>
                        <li><a className="" href="#">Korean <span>Soon</span></a></li>
                        <li><a className="" href="#">Japanese <span>Soon</span></a></li>
                      </ul>
                    </div>
                    )}
            <button className="navbar-toggler" type="button" onClick={() => {homeNav();}}>
            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H21C21.5523 0 22 0.447715 22 1C22 1.55228 21.5523 2 21 2H1C0.447715 2 0 1.55228 0 1Z" fill="white" fill-opacity="0.9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 8.44772 0.447715 8 1 8H21C21.5523 8 22 8.44772 22 9C22 9.55228 21.5523 10 21 10H1C0.447715 10 0 9.55228 0 9Z" fill="white" fill-opacity="0.9"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 17C0 16.4477 0.447715 16 1 16H21C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18H1C0.447715 18 0 17.5523 0 17Z" fill="white" fill-opacity="0.9"/>
              </svg>
            </button>
          </div>

          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <div className="d-lg-none d-flex justify-content-between text-end p-4">
              <Link to="/" className="navbar-brand">
                <img src="assets/logo.svg" className='op-dark' alt="" />
                <img src="assets/logo-vm.svg" className='op-light' alt="" />
              </Link>
              <a href="javascript:void(0)" onClick={() => {homeNav();}} className="cross-btn">
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className={`navbar-nav align-items-center px-4 ${isNodePage ? 'ms-auto not-visible' : 'mx-auto'} ${isMenuOpen ? 'is-visible' : ''}`} >
              <li className="nav-item indi"> <a  href="javascript:void(0)" className="nav-link menu-link" > 
                <div className="d-md-inline-block d-none">Individuals</div> <a href="javascript:void(0)" onClick={() => {megaMenu();}} className='d-md-none d-inline-block'>
              Individuals <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_662_1901)">
              <path d="M5.3902 7.7779L9.84025 3.32777C9.94325 3.22485 10 3.08745 10 2.94095C10 2.79445 9.94325 2.65705 9.84025 2.55412L9.51261 2.2264C9.29911 2.01315 8.95212 2.01315 8.73895 2.2264L5.00207 5.96328L1.26105 2.22225C1.15804 2.11933 1.02072 2.0625 0.874302 2.0625C0.727717 2.0625 0.590401 2.11933 0.487312 2.22225L0.159754 2.54998C0.0567474 2.65298 -3.18131e-08 2.7903 -3.8217e-08 2.9368C-4.46208e-08 3.0833 0.0567474 3.2207 0.159754 3.32363L4.61386 7.7779C4.7172 7.88107 4.85516 7.93774 5.00183 7.93741C5.14906 7.93774 5.28695 7.88107 5.3902 7.7779Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_662_1901">
              <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
              </clipPath>
              </defs>
              </svg></a></a> 
              <div className="mega-top">
              <div className="mega-menu">
                  <div className="menu">
                    <div className="row">
                      <div className="col-md-6">
                        <a href="javascript:void(0)" className='back-btn' onClick={() => {backBtn();}} ><svg className='me-1' width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_662_2296)">
                          <path d="M2.2221 5.3902L6.67223 9.84025C6.77515 9.94325 6.91255 10 7.05905 10C7.20555 10 7.34295 9.94325 7.44588 9.84025L7.7736 9.51261C7.98685 9.29911 7.98685 8.95212 7.7736 8.73895L4.03672 5.00207L7.77775 1.26105C7.88067 1.15804 7.9375 1.02072 7.9375 0.874302C7.9375 0.727717 7.88067 0.590401 7.77775 0.487312L7.45003 0.159754C7.34702 0.0567474 7.2097 -6.36262e-08 7.0632 -7.64339e-08C6.9167 -8.92416e-08 6.7793 0.0567473 6.67637 0.159754L2.2221 4.61386C2.11893 4.7172 2.06226 4.85516 2.06259 5.00183C2.06226 5.14906 2.11893 5.28695 2.2221 5.3902Z" fill="white"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_662_2296">
                          <rect width="10" height="10" fill="white" transform="translate(10 10) rotate(-180)"/>
                          </clipPath>
                          </defs>
                          </svg> Individuals</a>
                      <ul>
                      <li><a href="#ecoSystem" className='mb-md-4'>
                        <div className="menu-img"><img src="assets/menu/m1.svg" alt="" /></div>
                        <div className="menu-name">
                          <h4>Explore Ecosystem</h4>
                          <p>Explore the global network of dApps, institutions and partners building the future of finance</p>
                        </div>
                        </a></li>
                        <li><a href="#ecoSystem" className=''>
                        <div className="menu-img"><img src="assets/menu/m2.svg" alt="" /></div>
                        <div className="menu-name">
                          <h4>StratoVM Media Hub</h4>
                          <p>Connect to get started with your StratoVM journey</p>
                        </div>
                        </a></li>
                        
                    </ul>
                      </div>

                      <div className="col-md-6">
                      <ul>
                      <li><a href="https://explorer.stratovm.io/" target={'_blank'} className='mb-md-4'>
                        <div className="menu-img"><img src="assets/menu/m5.svg" alt="" /></div>
                        <div className="menu-name">
                          <h4>StratoVM Explorer</h4>
                          <p>Interact with real time activity and transactions on the StratoVM blockchain</p>
                        </div>
                        </a></li>
                        <li><a href="https://docs.stratovm.io/" target={"_blank"} className='last'>
                        <div className="menu-img"><img src="assets/menu/m6.svg" alt="" /></div>
                        <div className="menu-name">
                          <h4>StratoVM Docs</h4>
                          <p>Read the StartoVM chain documentation to understand its architecture and tech stack.</p>
                        </div>
                        </a></li>
                       
                    </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </li>
              <li className="nav-item"> <Link to={"/#developer"} className="nav-link menu-link" onClick={() => {homeNavClose();}}>Developers </Link>
               </li>
              <li className="nav-item"> <Link to={"/#ecoSystem"} className="nav-link menu-link" onClick={() => {homeNavClose();}}>Ecosystem </Link> </li>
              <li className="nav-item"> <Link to={"/#news"} className="nav-link menu-link" onClick={() => {homeNavClose();}}>Press </Link> </li>
              <li className="nav-item"> <NavLink to={"/nodesale"} className="nav-link menu-link" onClick={() => {homeNavClose();}}>Node Sale </NavLink> </li>             
              <li className="nav-item"> <a  href="https://app.galxe.com/quest/X8QrV4cqcjYssXZ2EpS6va/GCdtztd8zf"
              target={"_blank"} className="nav-link menu-link" onClick={() => {homeNavClose();}}>Testnet NFT </a> </li>        
              <li className="nav-item"> <a  href="https://medium.com/@StratoVM_/stratovm-dash-your-personal-hub-for-the-svm-points-7eb933e303a9" 
              target={"_blank"} className="nav-link menu-link" onClick={() => {homeNavClose();}}>StratoVM Points </a> </li>                           
            </ul>
            <div className="d-md-flex d-none align-items-center gap-3 pt-lg-0 pt-5 px-4 px-md-0">
            {isNodePage && (
            <a href='javascript:void(0)' className='d-flex' onClick={toggleMenu}>
              <div className="op-dark">
              <img src="assets/nodesalev2/close.svg" className={`${isMenuOpen ? 'd-block' : 'd-none'}`} alt="" />
              <img src="assets/nodesalev2/open.svg" className={`${isMenuOpen ? 'd-none' : 'd-block'}`} alt="" />
              </div>
              <div className="op-light">
              <img src="assets/nodesalev2/close-vm.svg" className={`${isMenuOpen ? 'd-block' : 'd-none'}`} alt="" />
              <img src="assets/nodesalev2/open-vm.svg" className={`${isMenuOpen ? 'd-none' : 'd-block'}`} alt="" />
              </div>
            </a>)}
              <a href="javascript:void(0)" className="toogle-btn" onClick={toggleTheme}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_509_17)">
                  <path d="M15.9986 6.21228C10.5931 6.21228 6.21094 10.5944 6.21094 16C6.21094 21.4056 10.5931 25.7877 15.9986 25.7877C21.4042 25.7877 25.7863 21.4056 25.7863 16C25.7863 10.5944 21.4042 6.21228 15.9986 6.21228ZM15.9986 24V8C20.4169 8 23.9986 11.5818 23.9986 16C23.9986 20.4183 20.4168 24 15.9986 24Z" fill="white"/>
                  <path d="M23.865 8.13412C24.03 8.30256 24.2549 8.39888 24.4907 8.40231C24.7265 8.39897 24.9514 8.30256 25.1164 8.13412L27.3063 5.94418C27.7013 5.64793 27.7813 5.0877 27.4851 4.69274C27.189 4.29778 26.6286 4.2178 26.2337 4.51395C26.1659 4.56477 26.1057 4.62499 26.0549 4.69274L23.8649 6.88269C23.5193 7.19972 23.4962 7.73694 23.8134 8.08245C23.8299 8.10042 23.8471 8.11761 23.865 8.13412Z" fill="white"/>
                  <path d="M7.95566 23.866C7.63786 23.6276 7.20081 23.6276 6.88301 23.866L4.69307 26.0559C4.34747 26.3729 4.32437 26.9102 4.64148 27.2557C4.65791 27.2736 4.67519 27.2908 4.69307 27.3073C5.0101 27.6529 5.54732 27.676 5.89283 27.3589C5.9108 27.3424 5.92799 27.3251 5.94442 27.3073L8.13436 25.1173C8.43061 24.7224 8.35062 24.1621 7.95566 23.866Z" fill="white"/>
                  <path d="M27.3076 26.0559L25.1177 23.866C24.7227 23.5697 24.1625 23.6498 23.8662 24.0448C23.6279 24.3626 23.6279 24.7996 23.8662 25.1174L26.0562 27.3074C26.3732 27.653 26.9104 27.6761 27.256 27.3589C27.2739 27.3425 27.2911 27.3252 27.3075 27.3074C27.6531 26.9903 27.6762 26.4531 27.3591 26.1076C27.3428 26.0895 27.3256 26.0724 27.3076 26.0559Z" fill="white"/>
                  <path d="M6.8844 8.13412C7.20143 8.47972 7.73865 8.50282 8.08417 8.1857C8.10213 8.16928 8.11933 8.152 8.13575 8.13412C8.48135 7.81709 8.50445 7.27987 8.18734 6.93435C8.17091 6.91639 8.15363 6.89919 8.13575 6.88277L5.94581 4.69282C5.55085 4.39658 4.99061 4.47665 4.69437 4.87161C4.45604 5.18941 4.45604 5.62646 4.69437 5.94426L6.8844 8.13412Z" fill="white"/>
                  <path d="M15.9993 4.87152C16.493 4.87152 16.8932 4.47134 16.8932 3.97766V0.893858C16.8932 0.400179 16.493 0 15.9993 0C15.5056 0 15.1055 0.400179 15.1055 0.893858V3.97766C15.1055 4.47134 15.5056 4.87152 15.9993 4.87152Z" fill="white"/>
                  <path d="M15.9993 27.1286C15.5056 27.1286 15.1055 27.5287 15.1055 28.0224V31.1062C15.1055 31.5999 15.5056 32.0001 15.9993 32.0001C16.493 32.0001 16.8932 31.5999 16.8932 31.1062V28.0224C16.8932 27.5287 16.493 27.1286 15.9993 27.1286Z" fill="white"/>
                  <path d="M31.1066 15.1062H28.0228C27.5291 15.1062 27.1289 15.5064 27.1289 16.0001C27.1289 16.4937 27.5291 16.8939 28.0228 16.8939H31.1066C31.6002 16.8939 32.0004 16.4937 32.0004 16.0001C32.0004 15.5064 31.6002 15.1062 31.1066 15.1062Z" fill="white"/>
                  <path d="M4.87152 16.0001C4.87152 15.5064 4.47134 15.1062 3.97766 15.1062H0.893858C0.400179 15.1062 0 15.5064 0 16.0001C0 16.4937 0.400179 16.8939 0.893858 16.8939H3.97766C4.47134 16.8939 4.87152 16.4937 4.87152 16.0001Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_509_17">
                  <rect width="32" height="32" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>
                  </a>
                  {!isNodePage && (
                  <div className="dropdown">
                      <button className="language-dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" 
                      data-bs-toggle="dropdown" aria-expanded="false">
                        EN
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                        <li className='active'><a className="" href="#">English</a></li>
                        <li><a className="" href="#">Arabic <span>Soon</span></a></li>
                        <li><a className="" href="#">Chinese <span>Soon</span></a></li>
                        <li><a className="" href="#">Spanish <span>Soon</span></a></li>
                        <li><a className="" href="#">French <span>Soon</span></a></li>
                        <li><a className="" href="#">Korean <span>Soon</span></a></li>
                        <li><a className="" href="#">Japanese <span>Soon</span></a></li>
                      </ul>
                    </div>)}

              {/* <a href="javascript:void(0)" target={'_blank'} className="primary-btn"></a> */}
            </div>

            <div className="d-md-none d-flex justify-content-between header-icons w-100">
            <div className="d-flex align-items-center gap-2">
            {!isNodePage && (
                  <div className="dropdown dropup">
                      <button className="language-dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" 
                      data-bs-toggle="dropdown" aria-expanded="false">
                        EN
                      </button>
                      <ul className="dropdown-menu dropdown-menu-start" aria-labelledby="dropdownMenuButton1">
                        <li className='active'><a className="" href="#">English</a></li>
                        <li><a className="" href="#">Arabic <span>Soon</span></a></li>
                        <li><a className="" href="#">Chinese <span>Soon</span></a></li>
                        <li><a className="" href="#">Spanish <span>Soon</span></a></li>
                        <li><a className="" href="#">French <span>Soon</span></a></li>
                        <li><a className="" href="#">Korean <span>Soon</span></a></li>
                        <li><a className="" href="#">Japanese <span>Soon</span></a></li>
                      </ul>
                    </div>
            )}
              <a href="javascript:void(0)" className="toogle-btn" onClick={toggleTheme}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_509_17)">
                  <path d="M15.9986 6.21228C10.5931 6.21228 6.21094 10.5944 6.21094 16C6.21094 21.4056 10.5931 25.7877 15.9986 25.7877C21.4042 25.7877 25.7863 21.4056 25.7863 16C25.7863 10.5944 21.4042 6.21228 15.9986 6.21228ZM15.9986 24V8C20.4169 8 23.9986 11.5818 23.9986 16C23.9986 20.4183 20.4168 24 15.9986 24Z" fill="white"/>
                  <path d="M23.865 8.13412C24.03 8.30256 24.2549 8.39888 24.4907 8.40231C24.7265 8.39897 24.9514 8.30256 25.1164 8.13412L27.3063 5.94418C27.7013 5.64793 27.7813 5.0877 27.4851 4.69274C27.189 4.29778 26.6286 4.2178 26.2337 4.51395C26.1659 4.56477 26.1057 4.62499 26.0549 4.69274L23.8649 6.88269C23.5193 7.19972 23.4962 7.73694 23.8134 8.08245C23.8299 8.10042 23.8471 8.11761 23.865 8.13412Z" fill="white"/>
                  <path d="M7.95566 23.866C7.63786 23.6276 7.20081 23.6276 6.88301 23.866L4.69307 26.0559C4.34747 26.3729 4.32437 26.9102 4.64148 27.2557C4.65791 27.2736 4.67519 27.2908 4.69307 27.3073C5.0101 27.6529 5.54732 27.676 5.89283 27.3589C5.9108 27.3424 5.92799 27.3251 5.94442 27.3073L8.13436 25.1173C8.43061 24.7224 8.35062 24.1621 7.95566 23.866Z" fill="white"/>
                  <path d="M27.3076 26.0559L25.1177 23.866C24.7227 23.5697 24.1625 23.6498 23.8662 24.0448C23.6279 24.3626 23.6279 24.7996 23.8662 25.1174L26.0562 27.3074C26.3732 27.653 26.9104 27.6761 27.256 27.3589C27.2739 27.3425 27.2911 27.3252 27.3075 27.3074C27.6531 26.9903 27.6762 26.4531 27.3591 26.1076C27.3428 26.0895 27.3256 26.0724 27.3076 26.0559Z" fill="white"/>
                  <path d="M6.8844 8.13412C7.20143 8.47972 7.73865 8.50282 8.08417 8.1857C8.10213 8.16928 8.11933 8.152 8.13575 8.13412C8.48135 7.81709 8.50445 7.27987 8.18734 6.93435C8.17091 6.91639 8.15363 6.89919 8.13575 6.88277L5.94581 4.69282C5.55085 4.39658 4.99061 4.47665 4.69437 4.87161C4.45604 5.18941 4.45604 5.62646 4.69437 5.94426L6.8844 8.13412Z" fill="white"/>
                  <path d="M15.9993 4.87152C16.493 4.87152 16.8932 4.47134 16.8932 3.97766V0.893858C16.8932 0.400179 16.493 0 15.9993 0C15.5056 0 15.1055 0.400179 15.1055 0.893858V3.97766C15.1055 4.47134 15.5056 4.87152 15.9993 4.87152Z" fill="white"/>
                  <path d="M15.9993 27.1286C15.5056 27.1286 15.1055 27.5287 15.1055 28.0224V31.1062C15.1055 31.5999 15.5056 32.0001 15.9993 32.0001C16.493 32.0001 16.8932 31.5999 16.8932 31.1062V28.0224C16.8932 27.5287 16.493 27.1286 15.9993 27.1286Z" fill="white"/>
                  <path d="M31.1066 15.1062H28.0228C27.5291 15.1062 27.1289 15.5064 27.1289 16.0001C27.1289 16.4937 27.5291 16.8939 28.0228 16.8939H31.1066C31.6002 16.8939 32.0004 16.4937 32.0004 16.0001C32.0004 15.5064 31.6002 15.1062 31.1066 15.1062Z" fill="white"/>
                  <path d="M4.87152 16.0001C4.87152 15.5064 4.47134 15.1062 3.97766 15.1062H0.893858C0.400179 15.1062 0 15.5064 0 16.0001C0 16.4937 0.400179 16.8939 0.893858 16.8939H3.97766C4.47134 16.8939 4.87152 16.4937 4.87152 16.0001Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_509_17">
                  <rect width="32" height="32" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>
                  </a>
            </div>
                   <div className="d-flex flex-wrap justify-content-end gap-2">
                   <a href="https://twitter.com/StratoVM_" target={"_blank"} className="social-ic">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_740_1704)">
                    <path d="M13.0458 9.3155L21.0597 0H19.1607L12.2022 8.08852L6.64453 0H0.234375L8.63871 12.2313L0.234375 22H2.13352L9.48183 13.4583L15.3512 22H21.7613L13.0454 9.3155H13.0458ZM10.4447 12.339L9.59316 11.1211L2.81781 1.42964H5.73478L11.2026 9.25094L12.0541 10.4689L19.1616 20.6354H16.2446L10.4447 12.3395V12.339Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_740_1704">
                    <rect width="22" height="22" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg> </a>
                   <a href="https://discord.gg/StratoVM" target={"_blank"} className="social-ic">
                    <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.16892 15.212C12.2582 17.5624 17.4402 18.0203 23.3125 14.3617C23.2695 14.4256 22.1079 16.1277 19.0532 17C19.6985 17.8724 20.5806 18.8723 20.5806 18.8723C22.5381 18.8723 24.4527 18.3191 26.109 17.2553C27.3997 16.4042 28.1311 14.9362 27.9805 13.4043C27.7224 10.7872 27.0985 8.23405 26.1305 5.78729C24.9044 2.59582 21.9573 0.383032 18.5369 0.0638598C18.2358 0.0425732 18.0207 0.0425734 17.8916 0.0425734L17.5475 0.382969C21.4841 1.48937 23.4417 3.21276 23.4846 3.27662C17.4399 0.255312 10.298 0.212802 4.21019 3.14896C4.21019 3.14896 6.14625 1.25534 10.427 0.255313L10.1689 0C9.71713 0 9.28689 0.042573 8.83519 0.10637C5.67296 0.638281 3.02707 2.78721 1.88693 5.74466C0.897379 8.29785 0.252046 10.9787 0.0154353 13.7021C-0.113631 15.1489 0.574745 16.5745 1.77939 17.4043C3.37123 18.4468 5.26424 19 7.17878 19C7.17878 19 7.95318 18 8.72764 17.1064C5.82355 16.2553 4.64043 14.5532 4.61891 14.4893L5.16545 14.7654C5.49217 14.9305 5.82713 15.08 6.16892 15.212ZM9.54508 13.3617C8.14681 13.3191 7.04972 12.1489 7.09276 10.7447C7.1358 9.42553 8.21138 8.36171 9.54508 8.31913C10.9434 8.36171 12.0405 9.5319 11.9974 10.9362C11.9328 12.2554 10.8788 13.3192 9.54508 13.3617ZM18.3218 13.3617C16.9235 13.3191 15.8264 12.1489 15.8695 10.7447C15.9125 9.42553 16.9881 8.36171 18.3218 8.31913C19.7201 8.36171 20.8172 9.5319 20.7741 10.9362C20.7311 12.2554 19.6555 13.3192 18.3218 13.3617Z" fill="white"/>
                    </svg></a>
                    <a href="https://t.me/StratoVMAnnouncements" target={"_blank"} className="social-ic">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1245_98)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM17.562 8.161C17.382 10.058 16.6 14.663 16.203 16.788C16.035 17.688 15.703 17.989 15.383 18.018C14.686 18.082 14.157 17.557 13.482 17.115C12.426 16.423 11.829 15.992 10.804 15.316C9.619 14.535 10.387 14.106 11.062 13.405C11.239 13.221 14.309 10.428 14.369 10.175C14.376 10.143 14.384 10.025 14.313 9.963C14.242 9.901 14.139 9.922 14.065 9.939C13.959 9.963 12.272 11.078 9.003 13.284C8.524 13.613 8.09 13.773 7.701 13.765C7.273 13.756 6.449 13.523 5.836 13.323C5.085 13.079 4.487 12.949 4.539 12.535C4.566 12.319 4.863 12.098 5.431 11.872C8.929 10.348 11.262 9.343 12.429 8.857C15.762 7.471 16.454 7.23 16.906 7.222C17.472 7.214 17.608 7.681 17.562 8.161Z" fill="white"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1245_98">
                      <rect width="24" height="24" fill="white"/>
                      </clipPath>
                      </defs>
                      </svg>
                      </a>
                    <a href="https://medium.com/@StratoVM_" target={"_blank"} className="social-ic">
                    <svg width="28" height="16" viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.7935 7.99979C15.7935 12.391 12.258 15.9508 7.89677 15.9508C3.53549 15.9508 0 12.391 0 7.99979C0 3.6086 3.53549 0.0488281 7.89677 0.0488281C12.258 0.0488281 15.7935 3.6086 15.7935 7.99979Z" fill="white"/>
                    <path d="M24.4593 8.00005C24.4593 12.1336 22.6915 15.4845 20.5109 15.4845C18.3303 15.4845 16.5625 12.1336 16.5625 8.00005C16.5625 3.86649 18.3303 0.515625 20.5109 0.515625C22.6915 0.515625 24.4593 3.86649 24.4593 8.00005Z" fill="white"/>
                    <path d="M28.0039 7.99967C28.0039 11.7032 27.3821 14.7054 26.6152 14.7054C25.8482 14.7054 25.2266 11.7032 25.2266 7.99967C25.2266 4.29618 25.8482 1.29395 26.6152 1.29395C27.3821 1.29395 28.0039 4.29618 28.0039 7.99967Z" fill="white"/>
                    </svg></a>
                      <a href="https://app.galxe.com/quest/X8QrV4cqcjYssXZ2EpS6va" target={"_blank"} className="social-ic">
                      <img src="assets/galex.svg" alt="" /> </a>
                      <a href="https://github.com/StratoVM" target={"_blank"} className="social-ic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.6rem" height="1.6rem" viewBox="0 0 24 24"><path fill="white" d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33s1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2"/></svg>
                    </a>
                   </div>
                </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
