import React, { useEffect, useState } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';
import { CountDown } from './CountDown';
import Categories from './Apps/Categories';



export default function NodeSaleV2() {

    useEffect(function () {
        Aos.init({ disable: 'mobile' });
      }, []);

  const location = useLocation();

  useEffect(() => {
  window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div className="nodesale v2">
        {/* Banner */}
      {/* <div className="bg-grad">
        <section className="banner" id="home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 mb-md-0 mb-4" data-aos="fade-up" data-aos-duration="1800">
                <h1 className="mb-0">
                Buy a StratoVM Node License NFT and Earn $SVM
                </h1>

                <p className="my-4 pe-lg-5">
                BTCFi's market value is projected to reach tens of billions in the short term and exceed a 
                trillion dollars in the long term. StratoVM nodes are key to decentralizing the network, 
                allowing anyone to earn $SVM with a simple one-click setup. <b>Apply for the whitelist today 
                to get priority in the private sale.</b>
                </p>
               
                <div className="d-none d-md-flex flex-md-row flex-column gap-3 mb-lg-5 mb-4">
                <a href="https://whitelist.stratovm.io/" target="_blank" rel="noopener noreferrer" className='primary-btn text-uppercase'>Apply for Whitelist</a>
                <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20240827T110000Z%2F20240827T120000Z&details=StratoVM%20Node%20Sale%20Whitelist%20Event&location=https%3A%2F%2Fstratovm.io%2F&text=StratoVM%20Node%20Sale%20%28Whitelist%29" target={"_blank"} rel="noopener noreferrer" className='learn-btn-1'>create reminder <span className='soonbadge'>Google Calendar</span></a>

                </div>
                
                
              </div>

              <div className="col-md-5" data-aos="zoom-in" data-aos-duration="1800">
                
              <div className="sale-card mb-md-0 mb-5">
                <h2 className='mt-lg-3'><span>Node license</span> <br />
                whitelist sale</h2>

                <CountDown targetDate={new Date('2024-08-27T13:00:00+02:00')} />

                <div className="grow"><img src="assets/nodesalev2/grow.png" alt="" /></div>
                <a href='https://medium.com/@StratoVM_/stratovm-node-sale-unleashing-bitcoins-trillion-dollar-economy-to-propel-btcfi-1878d712c287' target={"_blank"} className="com-soon">Read More</a>
                </div>   
                <div className="d-flex d-md-none flex-column gap-3  mt-4">
                <a href="https://whitelist.stratovm.io/" target="_blank" rel="noopener noreferrer" className='primary-btn text-uppercase'>Apply for Whitelist</a>
                <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20240827T110000Z%2F20240828T110000Z&details=&location=https%3A%2F%2Fstratovm.io%2F&text=StratoVM%20Node%20Sale%20%28Whitelist%29" target={"_blank"} rel="noopener noreferrer" className='learn-btn-1'>create reminder <span className='soonbadge'>Google Calendar</span>   </a>
                </div>           
              </div>
              
            </div>
          </div>
        </section>
        </div> */}

<div className="bg-grad">
        <img src="assets/b1.png" className='b1-img' alt="" />
        <section className="banner home-banner" id="home">
        <img src="assets/b2.png" className='b2-img' alt="" />
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 mb-md-0 mb-4" data-aos="fade-up" data-aos-duration="1800">
                <h1 className="mb-0">
                Our highly awaited Node Sale has officially launched
                </h1>

                {/* <div className="d-md-none d-block mt-4">
                <img src="assets/herosm.png" className='w-100 op-dark' alt="" />
              <img src="assets/herosm-vm.png" className='w-100 op-light' alt="" />
                </div> */}
                <p className="my-4 pe-lg-3">
                Purchase StratoVM Node License NFTs, earn $SVM, and get big rewards.
                </p>
                <div className="d-flex flex-md-row flex-column align-items-md-center gap-md-4 gap-2 mb-4">
                <div className="d-flex align-items-center gap-2">
                  <img src="assets/check2.png" height={20} alt="" />
                  <p className='mb-0 fs-14'>25% of $SVM Supply</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img src="assets/check2.png" height={20} alt="" />
                  <p className='mb-0 fs-14'>Earn SVM Points</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <img src="assets/check2.png" height={20} alt="" />
                  <p className='mb-0 fs-14'>Secure Discounted Nodes</p>
                </div>
                  </div>
                <div className="d-flex flex-md-row flex-column gap-3 mb-lg-5 mb-4">
                <a href="https://nodes.stratovm.io/" target="_blank" rel="noopener noreferrer" className='primary-btn text-capitalize fw-600'>Purchase Node Licenses</a>
                <a href="https://medium.com/@StratoVM_/stratovm-node-sale-unleashing-bitcoins-trillion-dollar-economy-to-propel-btcfi-1878d712c287" target="_blank" rel="noopener noreferrer" className='learn-btn'>Read Details
                <svg width="53" height="13" viewBox="0 0 53 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M53 6.5L43 0.726497V12.2735L53 6.5ZM0 7.5H44V5.5H0V7.5Z" fill="white"/>
                </svg>
                </a>
                </div>
                {/* <div className='text-md-start text-center'>
                <div className="sp-img op-dark"><img src="assets/sp.svg" alt="" /></div>
                <div className="sp-img op-light"><img src="assets/sp-vm.svg" alt="" /></div>
                </div> */}
                
              </div>

              <div className="col-md-5" data-aos="zoom-in" data-aos-duration="1800">
                <div className="banner-card">
                  <img src="assets/nodesale.png" className='w-100' alt="" />
                  <div className="banner-sale sale-card">
                  <CountDown targetDate={new Date('2024-08-29T13:00:00+02:00')} />
                  <div className="banner-badge">Countdown</div>
                  </div>
                </div>
              {/* <div className="d-md-block d-none">
              <Lottie className='op-dark'
                  loop
                  animationData={lottie}
                  play
                  style={{ width: '100%', height: '100%' }}
                />
                <Lottie className='op-light'
                  loop
                  animationData={lottieJson}
                  play
                  style={{ width: '100%', height: '100%' }}
                />
                </div> */}
                
              
              </div>
              
            </div>
          </div>
        </section>
        </div>

        <section className='blackBg py-4 feature'>
          <div className="container py-lg-2">
            <h5 className='text-center  mb-3' >As featured in</h5>
            <div className="row align-items-center justify-content-center text-md-start text-center">
            <div className="col-md-2 col-4 mb-md-0 mb-3 px-1 px-md-2">
              <img src="assets/partner/p33.png" className='op-dark w-100'  alt="" />
              <img src="assets/partner/p33-vm.png" className='op-light w-100'  alt="" />              
              </div>
              <div className="col-md-2 col-4 mb-md-0 mb-3">
              <img src="assets/partner/pb6.png" className='op-dark w-100 px-md-1' alt="" />
              <img src="assets/partner/pb6-vm.png" className='op-light w-100 px-md-1' alt="" />              
              </div>
              <div className="col-md-1 col-3 mb-md-0 mb-3 pe-0">
              <img src="assets/partner/p1.png" className='op-dark w-100' alt="" />
              <img src="assets/partner/p1-vm.png" className='op-light w-100' alt="" />              
              </div>
              <div className="col-md-2 col-3 text-md-end mb-md-0 mb-3">
              <img src="assets/partner/p2.png" className='op-dark' height={30} alt="" />
              <img src="assets/partner/p2-vm.png" className='op-light' height={30} alt="" />              
              </div>
              <div className="col-md-3 col-5 mb-md-0 mb-3">
              <img src="assets/partner/p4.png" className='op-dark w-100' alt="" />
              <img src="assets/partner/p4-vm.png" className='op-light w-100' alt="" />              
              </div>
              <div className="col-md-2 col-4 mb-md-0 mb-3">
              <img src="assets/partner/p6.png" className='op-dark w-100' alt="" />
              <img src="assets/partner/p6-vm.png" className='op-light w-100' alt="" />              
              </div>
              
            </div>
          </div>
        </section>

        <section className='landscape'>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 mb-md-0 mb-4">
                <img src="assets/nodesalev2/dot.png" style={{maxWidth: "30px"}} className='mb-4' alt="" />
                <h4 className='mb-md-3 mb-4'>StratoVM: Revolutionizing Bitcoin's DeFi Landscape</h4>
                <p className='pe-lg-4'>Bitcoin, the pioneer of cryptocurrencies, is poised for a significant leap forward in the realm of decentralized 
                  finance (DeFi). Enter StratoVM, a cutting-edge Layer 2 solution that aims to marry Bitcoin’s rock-solid security 
                  with the innovative spirit of DeFi. </p>

                  <a href="https://news.bitcoin.com/stratovm-revolutionizing-bitcoins-defi-landscape/" target={"_blank"} className='primary-btn-outline text-uppercase'>Read article on Bitcoin.com NEWS</a>
              </div>
              <div className="col-md-5">
                <img src="assets/nodesalev2/landscape.png" className='w-100' alt="" />
              </div>
            </div>
          </div>
        </section>


        <section className='pt-lg-5 mt-5'>
          <div className="container">
          <div className="row align-items-center mb-4 mb-lg-5">
              <div className="col-md-6 mb-4 mb-lg-0">
                <h2 className='mb-0'><span>Advantages</span> of Owning StratoVM Nodes</h2>
                
              </div>
              <div className="col-md-5">
                <p className=''>Owning StratoVM nodes offers several key benefits. <span>Node 
                  operators receive 25% of the $SVM supply via airdrops, get discount, earn points, and more.</span>  </p>
              </div>
            </div>
            

            <div className="row">
              <div className="col-md-4 mb-4">
              <div className="solo-card h-100 py-4">
              <div className="d-flex justify-content-between h-100 flex-column py-lg-2">
              <div className="mb-4">
                  <img src="assets/nodesalev2/ad2.png" className='w-100 op-dark' alt="" />
                  <img src="assets/nodesalev2/ad2-vm.png" className='w-100 op-light' alt="" />
                </div>
                  <h4 className='mb-3'>25% $SVM airdrop supply</h4>
                  <p>Node operators receive significant rewards, like 25% of $SVM supply airdropped as the ecosystem strengthens through $SVM airdrops.</p>

              </div>
            </div>
              </div>

              <div className="col-md-4 mb-4">
              <div className="solo-card h-100 py-4">
              <div className="d-flex justify-content-between h-100 flex-column py-lg-2">
              <div className="mb-4">
              <img src="assets/nodesalev2/ad1.png" className='w-100 op-dark' alt="" />
              <img src="assets/nodesalev2/ad1-vm.png" className='w-100 op-light' alt="" />
                </div>
                  <h4 className='mb-3'>Earn SVM points for Mainnet tokens</h4>
                  <p>Early adopters gain SVM points that can be converted to Mainnet tokens, offering long-term value for their investment.</p>
              </div>
            </div>
              </div>

              <div className="col-md-4 mb-4">
              <div className="solo-card h-100 py-4">
              <div className="d-flex justify-content-between h-100 flex-column py-lg-2">
              <div className="mb-4">
              <img src="assets/nodesalev2/ad3.png" className='w-100 op-dark' alt="" />
              <img src="assets/nodesalev2/ad3-vm.png" className='w-100 op-light' alt="" />
                </div>
                  <h4 className='mb-3'>Unlock a Special Bonus</h4>
                  <p>Receive 1 Mystery Box for every 5 nodes purchased. Our Mystery Boxes contain 
                    exciting rewards and benefits. The contents will be revealed after the node sale concludes.</p>                
              </div>
            </div>
              </div>
            </div>
            <div className="text-center mt-lg-5 mt-4">
                <a href="http://nodes.stratovm.io/" target={'_blank'} className='primary-btn text-uppercase'>Join Node Sale</a>
                    </div>
          </div>
        </section>

        <section className='pt-lg-5 pb-lg-5 mt-5 nodeBg'>
          <div className="container">
          <div className="row justify-content-center">
              <div className="col-md-10 text-center">
                <h2 className='mb-4 mb-lg-5 px-lg-5'>How to run a StratoVM Node</h2>
              </div>

              <div className="col-md-10 col-7 mx-auto mt-lg-5">
                <div className='d-md-block d-none'>
                <img src="assets/nodesalev2/step.png" className='w-100 op-dark ' alt="" />
                <img src="assets/nodesalev2/step-vm.png" className='w-100 op-light' alt="" />
                </div>
                <div className='d-md-none d-block'>
                <img src="assets/nodesalev2/stepsm.png" className='w-100 op-dark ' alt="" />
                <img src="assets/nodesalev2/stepsm-vm.png" className='w-100 op-light' alt="" />
                </div>
              </div>
            </div>
           
          </div>
        </section>

        <section className='pt-lg-5 mt-5'>
          <div className="container">
          <div className="row align-items-center mb-4 mb-lg-5">
              <div className="col-md-6 mb-4 mb-lg-0">
                <h2 className='mb-0'>The StratoVM <br className='d-md-block d-none' />
                <span>Ecosystem</span></h2>
                
              </div>
              <div className="col-md-5">
                <p className=''>The StratoVM Ecosystem is expanding rapidly. Our mission is to 
                  build one of the largest Bitcoin Layer 2 ecosystems – and we’re just getting started. </p>
              </div>
            </div>
            

            <Categories />
          </div>
        </section>


        <section className='blackBg earn-bg py-lg-5 mt-5'>
          <div className="container py-5">
          <div className="row justify-content-center">
              <div className="col-md-10 text-center">
                <h2 className='mb-3 mb-lg-4 px-lg-5'>Become a <span>StratoVM Node Operator</span> and <span>earn $SVM</span></h2>
                <div className=" text-center">
                  <p className='col-md-9 mx-auto mb-lg-4'>Owning StratoVM nodes offers several key benefits. <b>Sign up to be whitelisted 
                  today and ensure you are among the first to purchase our tier 1 node licenses for the biggest benefits.</b></p>
                    <div className="text-center">
                <a href="http://nodes.stratovm.io/" target={'_blank'} className='primary-btn text-uppercase'>Join Node Sale</a>
                    </div>
              </div>
            </div>
           
            </div>
          </div>
        </section>

        


        <section className='pt-lg-5 mt-5'>
          <div className="container">
            <div className="solo-card faq-card">
              <div className="row align-items-center">
                
                <div className="col-md-12">
                  <h3 className='mb-4 mb-lg-5 text-center' >FAQ</h3>

                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" 
                        aria-expanded="true" aria-controls="collapseOne">
                          <div className='d-flex align-items-center gap-3 flex-1'>1. What rewards can Nodes earn?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>Nodes can earn 25% of the StratoVM token rewards. Additionally, they will 
                            receive StratoVM Points that can be converted to $SVM Tokens on mainnet launch.</p>
                        </div>
                      </div>
                    </div>
{/* 
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" 
                        aria-expanded="false" aria-controls="collapseTwo">
                          <div className='d-flex align-items-center gap-3 flex-1'>2. How to get Whitelisted?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>Sign Up for the Waitlist: Just click <a href="https://whitelist.stratovm.io/" className='faq-link'>here</a> and 
                          register to join our waitlist. This alone makes you eligible to participate in the raffle for a 
                            chance to win a whitelist spot. Each user can only get one whitelist spot for buying one node during this event. <br /><br />

                            <i>Please note, being whitelisted does not guarantee a node purchase. It’s first come, first served.</i></p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" 
                        aria-expanded="false" aria-controls="collapseThree">
                          <div className='d-flex align-items-center gap-3 flex-1'>3. What are the benefits of being on the Whitelist?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>The Whitelist Round has a very limited supply and is only available to whitelisted wallets. Additionally, whitelisted users can enjoy a ~15% discount.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" 
                        aria-expanded="false" aria-controls="collapseFour">
                          <div className='d-flex align-items-center gap-3 flex-1'>4. Who can purchase licenses during the Whitelist round?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>Those who have a whitelist spot.</p>
                        </div>
                      </div>
                    </div> */}

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading5">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" 
                        aria-expanded="false" aria-controls="collapse5">
                          <div className='d-flex align-items-center gap-3 flex-1'>2. Is it technically difficult to run a node, and does it work on mobile?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>Running a node will be very easy with a one-click setup. Users don't need any specific hardware 
                            and can also choose to delegate their node with a simple click to earn rewards, so there's no need to keep their 
                            device constantly active. It also works seamlessly on mobile, allowing users to stake their NFT with one click 
                            and start earning rewards.</p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="heading6">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" 
                        aria-expanded="false" aria-controls="collapse6">
                          <div className='d-flex align-items-center gap-3 flex-1'>6. How many nodes can one person buy in the Whitelist round?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>There is no limit. Users can buy as many nodes as they want until the whitelist round sells out.</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading7">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" 
                        aria-expanded="false" aria-controls="collapse7">
                          <div className='d-flex align-items-center gap-3 flex-1'>7. What is a Whitelist?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>A whitelist is a pre-approved list of participants who are given exclusive access 
                            to certain privileges during a sale event. This system is used to reward and incentivize key 
                            contributors, partners, or early supporters of a project.</p>
                        </div>
                      </div>
                    </div> */}

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading8">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" 
                        aria-expanded="false" aria-controls="collapse8">
                          <div className='d-flex align-items-center gap-3 flex-1'>3. What is a node sale?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>A node sale involves selling node licenses and is a relatively new way of raising funds. It has gained significant popularity, 
                            as seen when the Sophon node sale (a Layer 2 on zkSync) <a href="https://www.theblock.co/post/293182/zksync-hyperchain-sophon-raises-60-million-via-node-sale" target={"_blank"} className='faq-link'>raised $60 million</a>. <br /> <br />
                            The process is straightforward: <br />
                            - Users buy an NFT (Node License) <br />
                            - Users can "stake" the NFT and thereby run the node <br />
                            - Users then start earning rewards in the protocol's native token
                            </p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading9">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" 
                        aria-expanded="false" aria-controls="collapse9">
                          <div className='d-flex align-items-center gap-3 flex-1'>4. What do I receive from participating in the node sale?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>You will receive a Node License, an ERC721 token (NFT). The token will be 
                            distributed after the end of the Node Sale. You can then stake this token to earn $SVM rewards.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading10">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" 
                        aria-expanded="false" aria-controls="collapse10">
                          <div className='d-flex align-items-center gap-3 flex-1'>5. How to run a Node?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>We will publish a guided tutorial on how to run a node. In essence, 
                            it's a very simple one-click process where users can stake their node and earn rewards.</p>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="heading11">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" 
                        aria-expanded="false" aria-controls="collapse11">
                          <div className='d-flex align-items-center gap-3 flex-1'>6. Will there be any rewards for Node License Owners before the StratoVM Mainnet Launch?</div>
                          <svg width="31" height="12" viewBox="0 0 31 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.5 6L20.5 0.226497V11.7735L30.5 6ZM0 7H21.5V5H0V7Z" fill="white"/>
                            </svg>
                        </button>
                      </h2>
                      <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          <p className=' mb-0'>Yes, owners will be able to stake their StratoVM node licenses on our app and start 
                            earning SVM points, which will increase the allocation of their $SVM airdrop.</p>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


       

    </>
  )
}
