import React, { useEffect, useState } from 'react';

interface TimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const calculateTimeLeft = (targetDate: Date): { timeLeft: TimeLeft, progress: number } => {
  const now = new Date();

  const distance = targetDate.getTime() - now.getTime();
  
  // Calculate progress
  const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  const totalDuration = targetDate.getTime() - startOfDay.getTime();
  const progress = Math.max(0, Math.min(100, ((totalDuration - distance) / totalDuration) * 100));

  if (distance < 0) {
    return {
      timeLeft: {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      },
      progress: 100
    };
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
  const seconds = Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0');

  return {
    timeLeft: {
      days,
      hours,
      minutes,
      seconds,
    },
    progress
  };
};

interface CountDownProps {
  targetDate: Date;
}

export const CountDown: React.FC<CountDownProps> = ({ targetDate }) => {
  const [timeData, setTimeData] = useState<{ timeLeft: TimeLeft, progress: number }>(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeData(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const renderDigits = (unit: string) => {
    return unit.split('').map((digit, index) => (
      <span key={index} className="digit">
        {digit}
      </span>
    ));
  };

  return (
    <>
      <div className="countdown-timer my-4">
        <div>
          <div className="time-unit">
            {renderDigits(timeData.timeLeft.days)}
          </div>
          <div className="label">days</div>
        </div>
        <div>:</div>
        <div>
          <div className="time-unit">
            {renderDigits(timeData.timeLeft.hours)}
          </div>
          <div className="label">hours</div>
        </div>
        <div>:</div>
        <div>
          <div className="time-unit">
            {renderDigits(timeData.timeLeft.minutes)}
          </div>
          <div className="label">minutes</div>
        </div>
        <div>:</div>
        <div>
          <div className="time-unit sec">
            {renderDigits(timeData.timeLeft.seconds)}
          </div>
          <div className="label">seconds</div>
        </div>
      </div>

      <div className="progress-bar mb-4">
        <div className="progress" style={{ width: `${timeData.progress}%` }} />
      </div>
    </>
  );
};
